(<template>
  <add-phone />
</template>)

<script>
  import WizardAddPhone from '@/components/wizard_components/WizardAddPhone';

  export default {
    components: {
      'add-phone': WizardAddPhone
    }
  };
</script>
